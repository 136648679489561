<template>
<div class="row mt-5 mb-5">
  <div class="col-md-12">
    <div class="row pl-4">
      <div class="col-md-12 " v-if="loanOverDueAmount || loanOverDueCount">
        <div class="mb-4">
          <div class="row">
            <div class="col-md-6 ">
              <b-alert variant="danger"  class="alert-boxes" show>You have <span>{{loanOverDueCount}}</span> overdue loan to be paid</b-alert>
            </div>
            <div class="col-md-6">
              <b-alert variant="danger" show class="alert-boxes">The amount is  <span>{{formatters(loanOverDueAmount)}}</span> total to be paid</b-alert>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 ml-3 mr-2">
        <div class="top-banners">
          <div class="row pl-4">
            <div class="col-md-4 mt-5 ">
              <p>Next Pay Day</p>
              <h2>{{ latestSchedule ? new Date(latestSchedule.value.loan_schedule.schddate).toDateString() : 'No schedule' }}</h2>
            </div>
            <div class="col-md-4 mt-5 ">
              <p>Amount to be paid</p>
              <h2>{{ formatters(latestSchedule ? latestSchedule.value.loan_schedule.install_amount : 0) }}</h2>
            </div>
            <div class="col-md-4 mt-5  mb-4">
              <router-link to="/loan" class="btn button button-cta cta pt-2" @click.prevent="">
                Pay Loan
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5 ml-1">
      <div class="col-md-4 mt-2">
        <div class="card-sets">
          <img src="/SAMTL.svg" alt="Totals">
          <h5>Loan Amount Collected:</h5>
          <h2>{{ formatters(latestSchedule.value.requested_amt) }}</h2>
        </div>
      </div>
      <div class="col-md-4 mt-2">
        <div class="card-sets">
          <img src="/SAMTL.svg" alt="Totals">
          <h5>Total Loan Interest:</h5>
          <h2>{{ formatters(latestSchedule.value.loaninterest) }}</h2>
        </div>
      </div>
      <div class="col-md-4 mt-2">
        <div class="card-sets">
          <img src="/SAMTL.svg" alt="Totals">
          <h5>Total Repayment Amount:</h5>
          <h2>{{ formatters(latestSchedule.value.requested_amt + latestSchedule.value.loaninterest) }}</h2>
        </div>
      </div>
      <div class="col-md-4 mt-4">
        <div class="card-sets">
          <img src="/SAMTL.svg" alt="Totals">
          <h5>Monthly Repayment:</h5>
          <h2>{{ formatters(latestSchedule.value.monthlypayment) }}</h2>
        </div>
      </div>
      <div class="col-md-4 mt-4">
        <div class="card-sets">
          <img src="/SAMTL.svg" alt="Totals">
          <h5>Outstanding Balance:</h5>
          <h2>{{ formatters(outstanding) }}</h2>
        </div>
      </div>
      <div class="col-md-4 mt-4">
        <div class="card-sets">
          <img src="/SAMTL.svg" alt="Totals">
          <h5>Number of Loans left to pay:</h5>
          <h2>{{ tenures }}</h2>
        </div>
      </div>

    </div>
  </div>
<!--  <div class="col-md-3">-->
<!--    <div class="row">-->
<!--      <div class="col-md-12">-->
<!--        <b-calendar-->
<!--            v-if="latestSchedule"-->
<!--            :value="latestSchedule.value.loan_schedule.schddate"-->
<!--            id="ex-disabled-readonly"-->
<!--            disabled readonly-->
<!--        ></b-calendar>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</div>
</template>

<script>

export default {
  name: "DashboardDesigns",
  props: {
    latestSchedule: {
      type: [Object, Array, String],
      required: false
    },
    loans: {
      type: [String, Number, Array, Object],
      required: false
    },
    totals: {
      type: [String, Number],
      required: false
    },
    scheduleLoans: {
      type: [Array, Object],
      required: false
    }
  },
  computed: {
    outstanding () {
      let amount = 0
      let ln = []

      if (this.scheduleLoans.length) {
        this.scheduleLoans.map(value => {
          if (value.loan_schedule && !value.loan_schedule.paid_yn) {
            ln.push(value)
          }
        })
        amount = ln.map(o => o.loan_schedule.install_amount).reduce((a, c) => { return a + c })
      }
      return amount
    },
    tenures () {
      let ln = []
      if (this.scheduleLoans.length) {
        this.scheduleLoans.map(value => {
          if (value.loan_schedule && !value.loan_schedule.paid_yn) {
            ln.push(value)
          }
        })
      }
      return ln.length
    },
    loanOverDueAmount () {
      return this.sortOverDuePaymentAmount(this.scheduleLoans)
    },

    loanOverDueCount () {
      return this.sortOverDuePaymentCount(this.scheduleLoans)
    }
  }
}
</script>

<style scoped>

</style>