<template>
  <admin-default v-slot:admin>
    <div class="application">
      <div class="container container-resize">
        <div class="row border-bottom p-5">
          <div class="col-md-9"><h1 class="application__secondary--title">Dashboard</h1></div>
          <div class="col-6 col-md-3">
            <h1 class="application__main--title">Welcome,</h1>
            <h1 class="application__tertiary--title">{{ user.name }}</h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <dashboard-designs :latest-schedule="latestScheduleDate" :loans="loads"
                             :totals="totals" :schedule-loans="schedule_loans"
          ></dashboard-designs>
        </div>
      </div>
      <div class="row m-4">
        <div class="col-md-12">
          <div class="mt-5 mb-5">
            <div class="accordion" role="tablist" v-if="tenuresSchedule.length">
              <b-card no-body class="mb-0" v-for="(value, index) in tenuresSchedule" :key="index">
                <b-card-header v-b-toggle="`${index}`">
                 <div class="notifications">
                   <p>
                     <img src="/images/wpforms.svg" alt="Alter forms">
                     <span v-if="value.paid_yn">Loan amount paid {{ formatters(value.loan_schedule.install_amount) }} {{value.paid_yn}}</span>
                     <span v-if="!value.paid_yn">Outstanding payment, amount {{ formatters(value.loan_schedule.install_amount) }} {{value.paid_yn}}</span>
                     <span class="span-color">{{ new Date(value.loan_schedule.schddate).toDateString() }}</span>
                   </p>
                   <p class="dots">
                     <img src="/images/dots.svg" alt="Dots">
                   </p>
                 </div>
                </b-card-header>
                <b-collapse :id="`${index}`" accordion="my-accordion" role="tabpanel">
                  <b-card-body >
                    <b-card-text>
                      <p>Total amount to be paid: {{ formatters(value.loan_schedule.schedule_principal) }}</p>
                      <p>Date to be paid: {{ new Date(value.loan_schedule.schddate).toDateString() }}</p>
                      <p>Interest to be paid on loan: {{ formatters(value.loan_schedule.schedule_interest) }}</p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </admin-default>
</template>

<script>
import {mapGetters} from 'vuex'
import AdminDefault from "../navigate/AdminDefault";
import DashboardDesigns from "./pages/DashboardDesigns";
export default {
name: "Dashboard",
  components: {DashboardDesigns, AdminDefault},
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      loads: 'loan/loans',
      schedule_loans: 'loan/schedule_loans',
      application: 'loan/loan_application'
    }),
    AllLoans () {
      const activeLoans = []
      if (this.loads.length) {
        this.loads.map(value => {
          activeLoans.push(value)
        })
      }
      return activeLoans
    },
    totals () {
      let amount = 0
      if (this.schedule_loans.length) {
        this.schedule_loans.map(value => {
          if (value.state !== 'paid') {
            amount += value.loan_schedule.install_amount
          }
        })
      }
      return amount
    },
    tenuresSchedule () {
      let ln = []
      if (this.schedule_loans.length) {
        this.schedule_loans.map(value => {
          if (value.loan_schedule && !value.loan_schedule.paid_yn) {
            ln.push(value)
          }
        })
      }
      return ln
    },
    latestScheduleDate () {
      let ln = []
      if (this.schedule_loans.length) {
        this.schedule_loans.map(value => {
          if (value.loan_schedule && !value.loan_schedule.paid_yn) {
            ln.push(value)
          }
        })
      }
      return this.getLatestDateAvailable(ln)
    },
    pending () {
      let count = 0
      if (this.application.length) {
        this.application.map(value => {
          if (value.state === 'draft' || value.state === 'ready' || value.state === 'readyto') {
            count += 1
          }
        })
      }
      return count
    },
    approved () {
      let count = 0
      if (this.application.length) {
        this.application.map(value => {
          if (value.state === 'paid'
              || value.state === 'running'
              || value.state === 'approve'
              || value.state === 'approved') {
            count += 1
          }
        })
      }
      return count
    },
    rejected () {
      let count = 0
      if (this.application.length) {
        this.application.map(value => {
          if (value.state === 'eliminate' || value.state === 'reject') {
            count += 1
          }
        })
      }
      return count
    }
  }

}
</script>

<style scoped>

</style>
